@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');

body {
    margin: 0;
    background-color: rgba(244, 244, 242, 1);
}

.div {
    width: 1440px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div-2 {
    background-color: rgba(244, 244, 242, 1);
    display: flex;
    width: 100%;
    flex-direction: column;
}

@media (max-width: 991px) {
    #vision .div-2 {
        max-width: 100%;
    }
}


#vision .div-11 {
    background-color: rgba(228, 224, 221, 1);
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 991px) {
    #vision .div-11 {
        max-width: 100%;
        padding: 20px;
    }
}

#vision .div-12 {
    display: flex;
    align-items: start;
    padding: 48px 32px 30px;
}

@media (max-width: 991px) {
    #vision .div-12 {
        max-width: 100%;
        margin: 0px;
        flex-wrap: wrap;
        padding: 0px;
    }
}

#vision .div-13 {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

#vision .img {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 96px;
}

@media (max-width: 991px) {
    #vision .img {
        width: 70px;
    }
}

#vision .div-14 {
    letter-spacing: -1.2px;
    color: rgba(124, 112, 102, 1);
    font: 700 60px Inter, sans-serif;
    padding: 0px 29px;
}

@media (max-width: 991px) {
    #vision .div-14 {
        max-width: 100%;
        font-size: 40px;
        padding: 0 0 0 10px;
    }
}

#vision .div-15 {
    background-color: rgba(244, 244, 242, 1);
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 991px) {
    #vision .div-15 {
        max-width: 100%;
        padding: 0px;
    }
}

#vision .div-16 {
    display: flex;
    width: 100%;
    align-items: flex-start;
    padding: 80px 32px 16px;
}

@media (max-width: 991px) {
    #vision .div-16 {
        max-width: 100%;
        flex-wrap: wrap;
        padding: 0;
    }
}

#vision .div-17 {
    align-self: start;
    display: flex;
    margin-top: 16px;
    align-items: center;
    justify-content: center;
    padding: 3px 0;
}

@media (max-width: 991px) {
    #vision .div-17 {
        margin-left: 20px;
    }
}

#vision .img-2 {
    aspect-ratio: 1.03;
    object-fit: auto;
    object-position: center;
    width: 188px;
}

@media (max-width: 991px) {
    #vision .img-2 {
        width: 128px;
    }
}

#vision .div-18 {
    align-self: end;
    display: flex;
    margin-top: 124px;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    flex: 1;
    padding: 0 20px;
}

@media (max-width: 991px) {
    #vision .div-18 {
        max-width: 100%;
        margin-top: 40px;
        overflow: hidden;
    }
}

#vision .img-3 {
    object-fit: auto;
    object-position: center;
    max-width: 100%;
    height: 85px;
}

@media (max-width: 991px) {
    #vision .img-3 {
        height: 65px;
    }
}

#vision .div-19 {
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 80px 80px;
}

@media (max-width: 991px) {
    #vision .div-19 {
        max-width: 100%;
        padding: 0 20px;
    }
}

#vision .div-20 {}

@media (max-width: 991px) {
    #vision .div-20 {
        max-width: 100%;
    }
}

#vision .div-21 {
    gap: 20px;
    display: flex;
}

@media (max-width: 991px) {
    #vision .div-21 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
}

#vision .column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 66%;
    margin-left: 0px;
    box-sizing: border-box;
}

@media (max-width: 991px) {
    #vision .column {
        width: 100%;
    }
}

#vision .div-22 {
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-weight: 400;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

@media (max-width: 991px) {
    #vision .div-22 {
        max-width: 100%;
        margin-top: 30px;
    }
}


@media (max-width: 991px) {
    #vision .img-4 {
        max-width: 100%;
    }
}

#vision .div-23 {
    letter-spacing: -0.72px;
    color: rgba(248, 150, 30, 1);
    margin-top: 24px;
    font: 36px Inter, sans-serif;
}

@media (max-width: 991px) {
    #vision .div-23 {
        max-width: 100%;
        font-size: 28px;
    }
}

#vision .div-24 {
    color: rgba(0, 0, 0, 1);
    margin-top: 25px;
    font: 20px/30px Inter, sans-serif;
}

@media (max-width: 991px) {
    #vision .div-24 {
        max-width: 100%;
        font-size: 18px;
    }
}

#vision .column-2 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 34%;
    margin-left: 20px;
    box-sizing: border-box;
}

@media (max-width: 991px) {
    #vision .column-2 {
        width: 100%;
        margin: 0;
    }
}

#vision .div-25 {
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-weight: 400;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.media-image {
    display: none;
}

@media (max-width: 991px) {
    #vision .div-25 {
        margin-top: 30px;
    }

    .media-video {
        display: none;
    }

    .media-image {
        display: block;
        width: 100%;
    }
}

#vision .img-5 {
    aspect-ratio: 0.87;
    object-fit: auto;
    object-position: center;
    width: 100%;
    border-radius: 12px;
}

#vision .div-26 {
    letter-spacing: -0.72px;
    color: rgba(248, 150, 30, 1);
    margin-top: 24px;
    font: 36px Inter, sans-serif;
}

@media (max-width: 991px) {
    #vision .div-26 {
        max-width: 100%;
        font-size: 28px;
    }
}

#vision .div-27 {
    color: rgba(85, 78, 78, 1);
    margin-top: 25px;
    font: 20px/30px Inter, sans-serif;
}

@media (max-width: 991px) {
    #vision .div-27 {
        max-width: 100%;
        font-size: 18px;
    }
}

#vision .div-28 {
    margin: 30px 0 16px;
}

@media (max-width: 991px) {
    #vision .div-28 {
        max-width: 100%;
    }
}

#vision .div-29 {
    gap: 20px;
    display: flex;
}

@media (max-width: 991px) {
    #vision .div-29 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
}

#vision .column-3 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 34%;
    margin-left: 0px;
}

@media (max-width: 991px) {
    #vision .column-3 {
        width: 100%;
    }
}

#vision .div-30 {
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-weight: 400;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

@media (max-width: 991px) {
    #vision .div-30 {
        margin-top: 30px;
    }
}

#vision .img-6 {
    aspect-ratio: 0.87;
    object-fit: auto;
    object-position: center;
    width: 100%;
    border-radius: 12px;
}

#vision .div-31 {
    letter-spacing: -0.72px;
    color: rgba(248, 150, 30, 1);
    margin-top: 24px;
    font: 36px Inter, sans-serif;
}

@media (max-width: 991px) {
    #vision .div-31 {
        max-width: 100%;
        font-size: 28px;
    }
}

#vision .div-32 {
    color: rgba(85, 78, 78, 1);
    margin-top: 25px;
    font: 20px/30px Inter, sans-serif;
}

@media (max-width: 991px) {
    #vision .div-32 {
        max-width: 100%;
        font-size: 18px;
    }
}

#vision .column-4 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 66%;
    margin-left: 20px;
}

@media (max-width: 991px) {
    #vision .column-4 {
        width: 100%;
        margin: 0;
    }
}

#vision .div-33 {
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-weight: 400;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

@media (max-width: 991px) {
    #vision .div-33 {
        max-width: 100%;
        margin-top: 30px;
    }
}

#vision .img-7 {
    aspect-ratio: 1.82;
    object-fit: auto;
    object-position: center;
    width: 100%;
    border-radius: 12px;
}

@media (max-width: 991px) {
    #vision .img-7 {
        max-width: 100%;
    }
}

#vision .div-34 {
    letter-spacing: -0.72px;
    color: rgba(248, 150, 30, 1);
    margin-top: 24px;
    font: 36px Inter, sans-serif;
}

@media (max-width: 991px) {
    #vision .div-34 {
        max-width: 100%;
        font-size: 28px;
    }
}

#vision .div-35 {
    color: rgba(85, 78, 78, 1);
    margin-top: 25px;
    font: 20px/30px Inter, sans-serif;
}

@media (max-width: 991px) {
    #vision .div-35 {
        max-width: 100%;
        font-size: 18px;
    }
}

#vision .div-36 {
    background-color: rgba(228, 224, 221, 1);
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    font-size: 48px;
    color: rgba(248, 150, 30, 1);
    font-weight: 500;
    letter-spacing: -0.96px;
    line-height: 60px;
    justify-content: center;
    padding: 0 60px;
}

@media (max-width: 991px) {
    #vision .div-36 {
        max-width: 100%;
        font-size: 40px;
        line-height: 56px;
        padding: 0 20px;
    }
}

#vision .div-37 {
    font-family: Inter, sans-serif;
    background-color: rgba(228, 224, 221, 1);
    margin: 0 20px;
    padding: 96px 32px;
    font-size: 40px;
}

@media (max-width: 991px) {
    #vision .div-37 {
        max-width: 100%;
        font-size: 28px;
        line-height: 56px;
        margin: 0px;
        padding: 20px;
    }
}

#vision .div-38 {
    background-color: rgba(244, 244, 242, 1);
    display: flex;
    margin-top: 5px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 2px 0;
}

@media (max-width: 991px) {
    #vision .div-38 {
        max-width: 100%;
    }
}

#vision .div-39 {
    display: flex;
    align-items: flex-start;
    padding: 80px 32px 16px;
}

@media (max-width: 991px) {
    #vision .div-39 {
        flex-wrap: wrap;
        padding: 0 20px;
    }
}

#vision .div-40 {
    border-radius: 4px;
    align-self: start;
    display: flex;
    margin-top: 16px;
    align-items: center;
    justify-content: center;
}

#vision .img-8 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 188px;
    border-radius: 4px;
}

@media (max-width: 991px) {
    #vision .img-9 {
        max-width: 100%;
        height: 100%;
    }
}

#vision .div-41 {
    align-self: end;
    display: flex;
    margin-top: 124px;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    flex: 1;
    padding: 0 20px;
}

@media (max-width: 991px) {
    #vision .div-41 {
        max-width: 100%;
        margin-top: 40px;
        overflow: hidden;
    }
}


#vision .img-9 {
    aspect-ratio: 2.08;
    object-fit: auto;
    object-position: center;
    width: 100%;
    padding: 10px 80px 0;
    box-sizing: border-box;
}

@media (max-width: 991px) {
    #vision .img-9 {
        max-width: 100%;
    }
}

#vision .div-42 {
    background-blend-mode: normal;
    background-color: rgba(228, 224, 221, 1);
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    font-size: 48px;
    color: rgba(87, 117, 144, 1);
    font-weight: 500;
    letter-spacing: -0.96px;
    line-height: 60px;
    justify-content: center;
    padding: 23px 60px;
}

@media (max-width: 991px) {
    #vision .div-42 {
        max-width: 100%;
        font-size: 40px;
        line-height: 56px;
        padding: 0 20px;
    }
}

#vision .div-43 {
    font-family: Inter, sans-serif;
    background-blend-mode: normal;
    background-color: rgba(228, 224, 221, 1);
    margin: 0 20px;
    padding: 96px 32px;
    font-size: 40px;
}

@media (max-width: 991px) {
    #vision .div-43 {
        max-width: 100%;
        font-size: 28px;
        line-height: 56px;
        margin: 0px;
        padding: 20px;
    }
}

.video-container {
    object-fit: auto;
    object-position: center;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    height: 450px;
}

@media (max-width: 991px) {
    .video-container {
        height: 100%;
        max-height:300px;
    }
}

.video-container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}