
.getintouch-div {
    background-color: rgba(85, 78, 78, 1);
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    justify-content: center;
    padding: 80px 60px;
}

@media (max-width: 991px) {
    .getintouch-div {
        max-width: 100%;
        padding: 20px 20px;
    }
}

.getintouch-link-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 16px 20px;
    padding: 0 60px;
}

@media (max-width: 991px) {
    .getintouch-link-div {
        max-width: 100%;
        margin-right: 10px;
        padding: 0 20px;
    }
}

.getintouch-img {
    aspect-ratio: 9.09;
    object-fit: auto;
    object-position: center;
    width: 100%;
    margin: 0 12px;
}

@media (max-width: 991px) {
    .getintouch-img {
        max-width: 100%;
        margin: 0px;
    }
}