.div-2 {
    background-color: rgba(244, 244, 242, 1);
    display: flex;
    width: 100%;
    flex-direction: column;
}

@media (max-width: 991px) {
    #contactus .div-2 {
        max-width: 100%;
    }
}

#contactus .div-11 {
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 991px) {
    #contactus .div-11 {
        max-width: 100%;
        padding: 20px;
    }
}

#contactus .div-12 {
    display: flex;
    align-items: start;
    padding: 48px 32px 30px;
}

@media (max-width: 991px) {
    #contactus .div-12 {
        max-width: 100%;
        margin-right: 10px;
        flex-wrap: wrap;
        padding: 0;
    }
}

#contactus .div-13 {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

@media (max-width: 991px) {
    #contactus .div-13 {
        display: flex;
        flex-direction: column;
    }
}

#contactus .img {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 96px;
}

#contactus .div-14 {
    letter-spacing: -1.2px;
    color: rgba(124, 112, 102, 1);
    align-self: stretch;
    font: 700 60px/72px Inter, sans-serif;
    padding: 0 29px;
    width: 100%;
}

@media (max-width: 991px) {
    #contactus .div-14 {
        width: 64%;
        font-size: 40px;
        line-height: 53px;
        padding: 0 0 0 10px;
    }
}

#contactus .div-15 {
    color: rgba(87, 117, 144, 1);
    font: 500 24px/32px Inter, sans-serif;
    margin-left:150px;
    width: 65%
}

@media (max-width: 991px) {
    #contactus .div-15 {
        max-width: 100%;
        margin:0px;
        width:100%;
    }
}

#contactus .div-16 {
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    padding: 80px 32px;
}

@media (max-width: 991px) {
    #contactus .div-16 {
        max-width: 100%;
        padding: 0 20px;
    }
}

#contactus .img-2 {
    aspect-ratio: 3.7;
    object-fit: auto;
    object-position: center;
    width: 100%;
    margin-top: 16px;
}

@media (max-width: 991px) {
    #contactus .img-2 {
        max-width: 100%;
    }
}

#contactus .div-17 {
    margin: 103px 0 72px;
}

@media (max-width: 991px) {
    #contactus .div-17 {
        max-width: 100%;
        margin: 40px 0;
    }
}

#contactus .div-18 {
    gap: 20px;
    display: flex;
}

@media (max-width: 991px) {
    #contactus .div-18 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
}

#contactus .column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 33%;
    margin-left: 0px;
}

@media (max-width: 991px) {
    #contactus .column {
        width: 100%;
    }
}

#contactus .div-19 {
    display: flex;
    flex-grow: 1;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    justify-content: center;
    padding: 0 60px;
}

@media (max-width: 991px) {
    #contactus .div-19 {
        margin-top: 30px;
        padding: 0 20px;
    }
}

#contactus .div-20 {
    display: flex;
    width: 187px;
    max-width: 100%;
    flex-direction: column;
}

#contactus .img-3 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 48px;
    align-self: center;
}

#contactus .div-21 {
    margin-top: 20px;
    color: rgba(102, 113, 137, 1);
    white-space: nowrap;
    padding: 0 58px;
    font: 600 20px Inter, sans-serif;
}

@media (max-width: 991px) {
    #contactus .div-21 {
        white-space: initial;
        padding: 0 20px;
    }
}

#contactus .div-22 {
    display: flex;
    margin-top: 8px;
    flex-direction: column;
    color: rgba(114, 82, 103, 1);
    padding: 3px 14px;
}

#contactus .div-23 {
    font-family: Inter, sans-serif;
}

#contactus .div-24 {
    font-family: Inter, sans-serif;
    align-self: center;
    margin-top: 4px;
}

#contactus .div-25 {
    font-family: Inter, sans-serif;
    color: rgba(123, 113, 99, 1);
    margin-top: 20px;
}

.column-2 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 33%;
    margin-left: 20px;
}

@media (max-width: 991px) {
    .column-2 {
        width: 100%;
        margin: 0px;
    }
}

#contactus .div-26 {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

@media (max-width: 991px) {
    #contactus .div-26 {
        margin-top: 30px;
    }
}

#contactus .img-4 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 48px;
    align-self: center;
}

#contactus .div-27 {
    margin-top: 20px;
    color: rgba(102, 113, 137, 1);
    white-space: nowrap;
    padding: 0 60px;
    font: 600 20px Inter, sans-serif;
}

@media (max-width: 991px) {
    #contactus .div-27 {
        white-space: initial;
        padding: 0 20px;
    }
}

#contactus .div-28 {
    font-family: Inter, sans-serif;
    margin-top: 8px;
    color: rgba(114, 82, 103, 1);
    padding: 0 60px;
}

@media (max-width: 991px) {
    #contactus .div-28 {
        padding: 0 20px;
    }
}

#contactus .div-29 {
    font-family: Inter, sans-serif;
    color: rgba(123, 113, 99, 1);
    line-height: 19px;
    align-self: center;
    margin-top: 20px;
}

#contactus .column-3 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 33%;
    margin-left: 20px;
}

@media (max-width: 991px) {
    #contactus .column-3 {
        width: 100%;
        margin: 0px;
    }
}

#contactus .div-30 {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

@media (max-width: 991px) {
    #contactus .div-30 {
        margin-top: 30px;
    }
}

#contactus .img-5 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 48px;
    align-self: center;
}

#contactus .div-31 {
    margin-top: 20px;
    color: rgba(102, 113, 137, 1);
    white-space: nowrap;
    padding: 0 58px;
    font: 600 20px Inter, sans-serif;
}

@media (max-width: 991px) {
    #contactus .div-31 {
        white-space: initial;
        padding: 0 20px;
    }
}

#contactus .div-32 {
    font-family: Inter, sans-serif;
    margin-top: 8px;
    color: rgba(114, 82, 103, 1);
    padding: 0 60px;
}

@media (max-width: 991px) {
    #contactus .div-32 {
        padding: 0 20px;
    }
}

#contactus .div-33 {
    font-family: Inter, sans-serif;
    color: rgba(123, 113, 99, 1);
    align-self: center;
    margin-top: 20px;
}
