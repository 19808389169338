
.div-56 {
    background-color: rgba(46, 44, 44, 1);
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    justify-content: center;
    padding: 0 60px;
}

@media (max-width: 991px) {
    .div-56 {
        max-width: 100%;
        padding: 0 20px;
    }
}

.div-57 {
    background-color: rgba(46, 44, 44, 1);
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 0 20px;
    padding: 80px 32px;
}

@media (max-width: 991px) {
    .div-57 {
        max-width: 100%;
        padding: 0px;
        margin: 0px;
    }
}

.div-58 {
    color: rgba(255, 255, 255, 1);
    margin-top: 16px;
    font: 700 24px Inter, sans-serif;
}

@media (max-width: 991px) {
    .div-58 {
        max-width: 100%;
        font-size: 14px;
    }
}

.div-59 {
    display: flex;
    margin-top: 22px;
    gap: 20px;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
}

@media (max-width: 991px) {
    .div-59 {
        flex-wrap: wrap;
        font-size: 12px;
    }
}

.footer-link {
    text-decoration: none;
    color: rgba(255, 255, 255, 1);
    font-family: Inter, sans-serif;
}

.div-61 {
    font-family: Inter, sans-serif;
}

.div-62 {
    font-family: Inter, sans-serif;
}

.div-63 {
    font-family: Inter, sans-serif;
}

.div-64 {
    display: flex;
    gap: 17px;
    margin: 30px 0 16px;
}

.img-8 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 49px;
}

@media (max-width: 991px) {
    .img-8 {
        aspect-ratio: 1;
        width: 40px;
        height: 40px
    }
}

.img-9 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 49px;
}

@media (max-width: 991px) {
    .img-9 {
        aspect-ratio: 1;
        width: 40px;
        height: 40px
    }
}

.img-10 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 49px;
}

@media (max-width: 991px) {
    .img-10 {
        aspect-ratio: 1;
        width: 40px;
        height: 40px
    }
}