.div-2 {
    background-color: rgba(244, 244, 242, 1);
    display: flex;
    width: 100%;
    flex-direction: column;
}

@media (max-width: 991px) {
    #aboutus .div-2 {
        max-width: 100%;
    }
}

#aboutus .div-11 {
    background-color: rgba(228, 224, 221, 1);
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 991px) {
    #aboutus .div-11 {
        max-width: 100%;
        padding: 20px;
    }
}

#aboutus .div-12 {
    display: flex;
    align-items: start;
    padding: 48px 32px 25px;
}

@media (max-width: 991px) {
    #aboutus .div-12 {
        max-width: 100%;
        margin-right: 10px;
        flex-wrap: wrap;
        padding: 0px;
    }
}

#aboutus .div-13 {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

#aboutus .img {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 96px;
}

@media (max-width: 991px) {
    #aboutus .img {
        width: 70px;
    }
}

#aboutus .div-14 {
    letter-spacing: -1.2px;
    color: rgba(124, 112, 102, 1);
    font: 700 60px Inter, sans-serif;
    padding: 0px 29px;
}

@media (max-width: 991px) {
    #aboutus .div-14 {
        max-width: 100%;
        font-size: 40px;
        padding: 0 0 0 10px;
    }
}

#aboutus .div-15 {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 80px;
    box-sizing: border-box;
}

@media (max-width: 991px) {
    #aboutus .div-15 {
        max-width: 100%;
        padding: 0 20px;
    }
}

#aboutus .div-16 {
    margin-top: 16px;
    width: 100%;
}

@media (max-width: 991px) {
    #aboutus .div-16 {
        max-width: 100%;
    }
}

#aboutus .div-17 {
    display: flex;
    gap: 20px;
}

@media (max-width: 991px) {
    #aboutus .div-17 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
}

#aboutus .column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 0px;
    box-sizing: border-box;
}

@media (max-width: 991px) {
    #aboutus .column {
        width: 100%;
    }
}

#aboutus .div-18 {
    border-radius: 12px;
    background-color: rgba(110, 144, 117, 1);
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    width: 100%;
}

@media (max-width: 991px) {
    #aboutus .div-18 {
        max-width: 100%;
        margin-top: 30px;
    }
}

#aboutus .div-19 {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 36px 32px;
}

@media (max-width: 991px) {
    #aboutus .div-19 {
        max-width: 100%;
        padding: 20px;
    }
}

#aboutus .div-20 {
    letter-spacing: -2px;
    font: 700 42px Inter, sans-serif;
}

@media (max-width: 991px) {
    #aboutus .div-20 {
        max-width: 100%;
        font-size: 28px;
    }
}

#aboutus .div-21 {
    letter-spacing: -0.5px;
    margin-top: 24px;
    font: 500 28px/42px Inter, sans-serif;
}

@media (max-width: 991px) {
    #aboutus .div-21 {
        max-width: 100%;
        font-size: 20px;
    }
}

#aboutus .column-2 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 20px;
}

@media (max-width: 991px) {
    #aboutus .column-2 {
        width: 100%;
        margin: 0;
    }
}

#aboutus .div-22 {
    border-radius: 12px;
    background-color: rgba(102, 113, 137, 1);
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    width: 100%;
}

@media (max-width: 991px) {
    #aboutus .div-22 {
        max-width: 100%;
        margin-top: 30px;
    }
}

#aboutus .div-23 {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 36px 32px;
}

@media (max-width: 991px) {
    #aboutus .div-23 {
        max-width: 100%;
        padding: 20px;
    }
}

#aboutus .div-24 {
    letter-spacing: -2px;
    font: 700 42px Inter, sans-serif;
}

@media (max-width: 991px) {
    #aboutus .div-24 {
        max-width: 100%;
        font-size: 28px;
    }
}

#aboutus .div-25 {
    letter-spacing: -0.5px;
    margin-top: 24px;
    font: 500 28px/42px Inter, sans-serif;
}

@media (max-width: 991px) {
    #aboutus .div-25 {
        max-width: 100%;
        font-size: 20px;
    }
}

#aboutus .div-26 {
    margin: 30px 0 16px;
}

@media (max-width: 991px) {
    #aboutus .div-26 {
        max-width: 100%;
    }
}

#aboutus .div-27 {
    gap: 20px;
    display: flex;
}

@media (max-width: 991px) {
    #aboutus .div-27 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
}

#aboutus .div-28 {
    border-radius: 12px;
    background-color: rgba(114, 82, 103, 1);
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    width: 100%;
}

@media (max-width: 991px) {
    #aboutus .div-28 {
        max-width: 100%;
    }
}

#aboutus .div-29 {
    border-radius: 12px;
    display: flex;
    padding-bottom: 80px;
    flex-direction: column;
}

@media (max-width: 991px) {
    #aboutus .div-29 {
        max-width: 100%;
    }
}

#aboutus .div-30 {
    display: flex;
    margin-bottom: 4px;
    flex-direction: column;
    padding: 32px 32px 36px;
}

@media (max-width: 991px) {
    #aboutus .div-30 {
        max-width: 100%;
        padding: 20px;
    }
}

#aboutus .div-31 {
    letter-spacing: -2px;
    font: 700 42px Inter, sans-serif;
}

@media (max-width: 991px) {
    #aboutus .div-31 {
        max-width: 100%;
        font-size: 28px;
    }
}

#aboutus .div-32 {
    letter-spacing: -0.5px;
    margin-top: 24px;
    font: 500 28px/42px Inter, sans-serif;
}

@media (max-width: 991px) {
    #aboutus .div-32 {
        max-width: 100%;
        font-size: 20px;
    }
}

#aboutus .column-3 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 20px;
}

@media (max-width: 991px) {
    #aboutus .column-3 {
        width: 100%;
        margin: 0;
    }
}

#aboutus .div-33 {
    border-radius: 12px;
    background-color: rgba(123, 113, 99, 1);
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    width: 100%;
}

@media (max-width: 991px) {
    #aboutus .div-33 {
        max-width: 100%;
        margin-top: 30px;
    }
}

#aboutus .div-34 {
    border-radius: 12px;
    display: flex;
    padding-bottom: 80px;
    flex-direction: column;
}

@media (max-width: 991px) {
    #aboutus .div-34 {
        max-width: 100%;
    }
}

#aboutus .div-35 {
    display: flex;
    margin-bottom: 4px;
    flex-direction: column;
    padding: 32px 32px 36px;
}

@media (max-width: 991px) {
    #aboutus .div-35 {
        max-width: 100%;
        padding: 20px;
    }
}

#aboutus .div-36 {
    letter-spacing: -2px;
    font: 700 42px Inter, sans-serif;
}

@media (max-width: 991px) {
    #aboutus .div-36 {
        max-width: 100%;
        font-size: 28px;
    }
}

#aboutus .div-37 {
    letter-spacing: -0.5px;
    margin-top: 24px;
    font: 500 28px/42px Inter, sans-serif;
}

@media (max-width: 991px) {
    #aboutus .div-37 {
        max-width: 100%;
        font-size: 20px;
    }
}

#aboutus .div-38 {
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    align-items: center;
    /* padding: 0 60px 80px; */
}

@media (max-width: 991px) {
    #aboutus .div-38 {
        max-width: 100%;
        padding: 0 20px;
    }
}

#aboutus .div-39 {
    display: flex;
    margin-bottom: 16px;
    width: 100%;
    max-width: 1021px;
    flex-direction: column;
}

@media (max-width: 991px) {
    #aboutus .div-39 {
        max-width: 100%;
    }
}

#aboutus .div-40 {
    align-self: center;
    max-width: 100%;}

@media (max-width: 991px) {
    #aboutus .div-40 {
        max-width: 100%;
    }
}

#aboutus .div-41 {
    gap: 20px;
    display: flex;
}

@media (max-width: 991px) {
    #aboutus .div-41 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
}

#aboutus .column-4 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 33%;
    margin-left: 0px;
}

@media (max-width: 991px) {
    #aboutus .column-4 {
        width: 100%;
    }
}

#aboutus .div-42 {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-size: 20px;
    color: rgba(85, 78, 78, 1);
    font-weight: 600;
    text-align: center;
}

@media (max-width: 991px) {
    #aboutus .div-42 {
        margin-top: 30px;
    }
}

#aboutus .img-2 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 96px;
    align-self: center;
}

#aboutus .div-43 {
    font-family: Inter, sans-serif;
    margin-top: 20px;
    padding: 0 60px;
}

@media (max-width: 991px) {
    #aboutus .div-43 {
        padding: 0 20px;
    }
}

#aboutus .column-5 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 33%;
    margin-left: 20px;
}

@media (max-width: 991px) {
    #aboutus .column-5 {
        width: 100%;
        margin: 0px;
    }
}

#aboutus .div-44 {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-size: 20px;
    color: rgba(85, 78, 78, 1);
    font-weight: 600;
    white-space: nowrap;
    text-align: center;
}

@media (max-width: 991px) {
    #aboutus .div-44 {
        margin-top: 30px;
        white-space: initial;
    }
}

#aboutus .img-3 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 96px;
    align-self: center;
}

#aboutus .div-45 {
    font-family: Inter, sans-serif;
    margin-top: 20px;
    padding: 0 60px;
}

@media (max-width: 991px) {
    #aboutus .div-45 {
        white-space: initial;
        padding: 0 20px;
    }
}

#aboutus .column-6 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 33%;
    margin-left: 20px;
}

@media (max-width: 991px) {
    #aboutus .column-6 {
        width: 100%;
        margin: 0px;
    }
}

#aboutus .div-46 {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-size: 20px;
    color: rgba(85, 78, 78, 1);
    font-weight: 600;
    text-align: center;
}

@media (max-width: 991px) {
    #aboutus .div-46 {
        margin-top: 30px;
    }
}

#aboutus .img-4 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 96px;
    align-self: center;
}

#aboutus .div-47 {
    font-family: Inter, sans-serif;
    margin-top: 20px;
    padding: 0 60px;
}

@media (max-width: 991px) {
    #aboutus .div-47 {
        padding: 0 20px;
    }
}

#aboutus .div-48 {
    align-self: center;
    margin-top: 100px;
    max-width: 100%;
}

@media (max-width: 991px) {
    #aboutus .div-48 {
        margin-top: 0px;
    }
}

#aboutus .div-49 {
    gap: 20px;
    display: flex;
}

@media (max-width: 991px) {
    #aboutus .div-49 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
}

#aboutus .div-50 {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    font-size: 20px;
    color: rgba(85, 78, 78, 1);
    font-weight: 600;
    text-align: center;
    margin: auto 0;
}

@media (max-width: 991px) {
    #aboutus .div-50 {
        margin-top: 30px;
    }
}

#aboutus .img-5 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 96px;
    align-self: center;
}

#aboutus .div-51 {
    font-family: Inter, sans-serif;
    margin-top: 20px;
    padding: 0 60px;
}

@media (max-width: 991px) {
    #aboutus .div-51 {
        padding: 0 20px;
    }
}
#aboutus .column-7 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 20px;
}

@media (max-width: 991px) {
    #aboutus .column-7 {
        width: 100%;
        margin: 0px;
    }
}

#aboutus .column-8 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 20px;
}

@media (max-width: 991px) {
    #aboutus .column-8 {
        width: 100%;
        margin: 0px;
    }
}

#aboutus .div-52 {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-size: 20px;
    color: rgba(85, 78, 78, 1);
    font-weight: 600;
    text-align: center;
    line-height: 30px;
}

@media (max-width: 991px) {
    #aboutus .div-52 {
        margin-top: 30px;
    }
}

#aboutus .img-6 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 96px;
    align-self: center;
}

#aboutus .div-53 {
    font-family: Inter, sans-serif;
    margin-top: 20px;
    padding: 0 60px;
}

@media (max-width: 991px) {
    #aboutus .div-53 {
        padding: 0 20px;
    }
}
