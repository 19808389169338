@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');

body{
    margin: 0;
    background-color: rgba(244, 244, 242, 1);
}
.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 991px) {
    .container {
        display: flex;
        justify-content:inherit;
        align-items: center;
    }
}
.div {
    width: 1440px;
    background-color: rgba(244, 244, 242, 1);
    display: flex;
    flex-direction: column;
}