
.div-3 {
    background-color: rgba(124, 112, 102, 1);
    display: flex;
    flex-direction: column;
    color: rgba(228, 224, 221, 1);
    justify-content: center;
    padding: 20px 32px;
}

@media (max-width: 991px) {
    .div-3 {
        max-width: 100%;
        padding: 20px;
    }
}

.div-4 {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
}

@media (max-width: 991px) {
    .div-4 {
        max-width: 100%;
        flex-wrap: wrap;
    }
}

.div-5 {
    margin: auto 0;
    font: 900 20px Inter, sans-serif;
}

@media (max-width: 991px) {
    .div-5 {
        margin-right: 20px
    }
}

.div-6 {
    display: flex;
    gap: 6px;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}

.div-7 {
    font-family: Inter, sans-serif;
    border-radius: 8px;
    white-space: nowrap;
    padding: 10px;
    color: rgba(228, 224, 221, 1);
    text-decoration: none;
}


.div-8 {
    font-family: Inter, sans-serif;
    border-radius: 8px;
    padding: 10px;
    color: rgba(228, 224, 221, 1);
    text-decoration: none;
}

.div-9 {
    font-family: Inter, sans-serif;
    border-radius: 8px;
    padding: 10px;
    color: rgba(228, 224, 221, 1);
    text-decoration: none;
}

.div-10 {
    font-family: Inter, sans-serif;
    border-radius: 8px;
    padding: 10px;
    color: rgba(228, 224, 221, 1);
    text-decoration: none;
}

@media (max-width: 991px) {
    .div-7 {
        white-space: initial;
        padding: 0 20px 0 0;
    }
    .div-8 {
        white-space: initial;
        padding: 0 20px 0 0;
    }
    .div-9 {
        white-space: initial;
        padding: 0 20px 0 0;
    }
    .div-10 {
        white-space: initial;
        padding: 0 20px 0 0;
    }
}