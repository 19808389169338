.div-2 {
    background-color: rgba(244, 244, 242, 1);
    display: flex;
    width: 100%;
    flex-direction: column;
}

@media (max-width: 991px) {
    #home .div-2 {
        max-width: 100%;
    }
}


#home .div-11 {
    background-color: rgba(228, 224, 221, 1);
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 991px) {
    #home .div-11 {
        max-width: 100%;
        padding: 20px;
    }
}

#home .div-12 {
    background-color: rgba(228, 224, 221, 1);
    display: flex;
    padding: 48px 32px 25px;
}

@media (max-width: 991px) {
    #home .div-12 {
        max-width: 100%;
        flex-wrap: wrap;
        padding: 0px;
    }
}

#home .div-13 {
    align-self: start;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

@media (max-width: 991px) {
    #home .div-13 {
        display: flex;
        flex-direction: column;
    }
}

#home .img {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 96px;
}

@media (max-width: 991px) {
    #home .img {
        width: 70px;
    }
}

#home .div-14 {
    font-size: 42px;
    color: rgba(85, 78, 78, 1);
    font-weight: 500;
    text-align: center;
    padding: 0px 29px;
}

@media (max-width: 991px) {
    #home .div-14 {
        display: flex;
        flex-direction: column;
        width: 80%;
        padding: 0px;
        font-size: 22px;
    }
}

#home .div-15 {
    font-family: Inter, sans-serif;
}

@media (max-width: 991px) {
    #home .div-15 {
        max-width: 100%;
    }
}

#home .div-16 {
    font-family: Inter, sans-serif;
    margin: 19px 22px 0;
}

@media (max-width: 991px) {
    #home .div-16 {
        max-width: 100%;
        margin-right: 10px;
    }
}

#home .div-17 {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 48px;
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    text-align: center;
    letter-spacing: -0.96px;
    justify-content: center;
}

@media (max-width: 991px) {
    #home .div-17 {
        max-width: 100%;
        font-size: 40px;
    }
}

#home .div-18 {
    flex-direction: column;
    align-items: center;
    margin: 110px 0 72px;
    position: absolute;
    z-Index: 1;
    font-size: 48px;
}

@media (max-width: 991px) {
    #home .div-18 {
        max-width: 100%;
        font-size: 24px;
        margin: 40px 0;
    }
}

#home .div-19 {
    font-family: Inter, sans-serif;
    align-self: stretch;
}

@media (max-width: 991px) {
    #home .div-19 {
        max-width: 100%;
    }
}

#home .div-20 {
    font-family: Inter, sans-serif;
    margin-top: 20px;
}

@media (max-width: 991px) {
    #home .div-20 {
        max-width: 100%;
    }
}

#home .div-21 {
    font-family: Inter, sans-serif;
    margin-top: 20px;
}

@media (max-width: 991px) {
    #home .div-21 {
        max-width: 100%;
    }
}

#home .div-22 {
    background-color: rgba(124, 112, 102, 1);
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    justify-content: center;
    padding: 0 60px;
}

@media (max-width: 991px) {
    #home .div-22 {
        max-width: 100%;
        padding: 0 20px;
    }
}

#home .div-23 {
    background-color: rgba(124, 112, 102, 1);
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    padding: 80px 32px 4px;
}

@media (max-width: 991px) {
    #home .div-23 {
        max-width: 100%;
        margin-right: 10px;
        padding: 0 20px;
    }
}

#home .div-24 {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.96px;
    padding: 11px 25px 3px;
}

@media (max-width: 991px) {
    #home .div-24 {
        max-width: 100%;
        font-size: 40px;
        padding: 0 20px;
    }
}

#home .div-25 {
    font-family: Inter, sans-serif;
    color: rgba(248, 150, 30, 1);
    line-height: 60px;
    font-size: 40px;
}

@media (max-width: 991px) {
    #home .div-25 {
        max-width: 100%;
        font-size: 20px;
        line-height: 40px;
    }
}

#home .div-26 {
    font-family: Inter, sans-serif;
    color: rgba(255, 255, 255, 1);
    align-self: center;
    margin-top: 13px;
}

@media (max-width: 991px) {}

#home .div-27 {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    justify-content: center;
    padding: 0 40px;
}

@media (max-width: 991px) {
    #home .div-27 {
        max-width: 100%;
        padding: 0 20px;
    }
}

#home .img-2 {
    aspect-ratio: 3.57;
    object-fit: auto;
    object-position: center;
    width: 100%;
}

@media (max-width: 991px) {
    #home .img-2 {
        max-width: 100%;
    }
}


#home .div-30 {
    background-color: rgba(46, 44, 44, 1);
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    justify-content: center;
    padding: 0 60px;
}

@media (max-width: 991px) {
    #home .div-30 {
        max-width: 100%;
        padding: 0 20px;
    }
}

#home .div-31 {
    background-color: rgba(46, 44, 44, 1);
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 0 20px;
    padding: 80px 32px;
}

@media (max-width: 991px) {
    #home .div-31 {
        max-width: 100%;
        margin-right: 10px;
        padding: 0 20px;
    }
}

#home .div-32 {
    color: rgba(255, 255, 255, 1);
    margin-top: 16px;
    font: 700 24px Inter, sans-serif;
}

@media (max-width: 991px) {
    #home .div-32 {
        max-width: 100%;
    }
}

#home .div-33 {
    display: flex;
    margin-top: 22px;
    gap: 20px;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
}

@media (max-width: 991px) {
    #home .div-33 {
        flex-wrap: wrap;
    }
}

#home .div-34 {
    font-family: Inter, sans-serif;
}

#home .div-35 {
    font-family: Inter, sans-serif;
}

#home .div-36 {
    font-family: Inter, sans-serif;
}

#home .div-37 {
    font-family: Inter, sans-serif;
}

#home .div-38 {
    display: flex;
    gap: 17px;
    margin: 30px 0 16px;
}

#home .img-4 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 49px;
}

#home .img-5 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 49px;
}

#home .img-6 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 49px;
}

.carousel .slide {
    background: none;
    height: 800px;
    overflow: hidden;
}

.carousel .slide img {
    aspect-ratio: 1.2;
    height: 100% !important;
    object-fit: cover;
}

@media (max-width: 991px) {
    .carousel .slide {
        height: 300px;
        overflow: hidden;
    }
}